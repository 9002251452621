exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acces-medecin-js": () => import("./../../../src/pages/acces-medecin.js" /* webpackChunkName: "component---src-pages-acces-medecin-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-prendre-rdv-js": () => import("./../../../src/pages/prendre-rdv.js" /* webpackChunkName: "component---src-pages-prendre-rdv-js" */),
  "component---src-pages-recherche-js": () => import("./../../../src/pages/recherche.js" /* webpackChunkName: "component---src-pages-recherche-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-lespages-js": () => import("./../../../src/templates/lespages.js" /* webpackChunkName: "component---src-templates-lespages-js" */)
}

